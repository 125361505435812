<template>
  <div>
    <van-nav-bar
      title="备注"
      right-text="完成"
      left-arrow
      @click-left="$router.go(-1)"
      @click-right="onClickRight"
    />

    <div
      class="Ge"
      style="margin: 17px 2% 0; 
      border: 1px solid #E0E0E0; 
      border-radius: 4px;"
    >
      <van-field
        v-model="message"
        rows="4"
        type="textarea"
        maxlength="50"
        placeholder="请输入口味、偏好等要求"
        show-word-limit
      />
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
  export default {
    data () {
      return {
        message: this.$route.query.userRemark
      }
    },
    methods: {
      ...mapMutations(['shopCar/user_remark']),
      onClickRight () {
        this['shopCar/user_remark'](this.message)
        this.$router.go(-1)
        if (this.message != '' || this.message != null) {
          this.$toast('已成功添加备注')
        }
      }
    }
  }
</script>

<style lang="less" scoped>
/deep/ .van-nav-bar__text {
  color: #323233;
}
.Ge {
  width: 96%;
  margin-left: 2%;
  /deep/ .van-field__control {
    font-size: 12px;
  }
}
.van-nav-bar,
.van-cell {
  background-color: #fafafa;
}
</style>